import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

export const ARROW_DIRECTIONS = {
  UP: 'UP',
  RIGHT: 'RIGHT',
  DOWN: 'DOWN',
  LEFT: 'LEFT',
};

const arrowDirections = {
  [ARROW_DIRECTIONS.UP]: 'rotate(-90deg)',
  [ARROW_DIRECTIONS.RIGHT]: '',
  [ARROW_DIRECTIONS.DOWN]: 'rotate(90deg)',
  [ARROW_DIRECTIONS.LEFT]: 'rotate(180deg)',
};

const ArrowIcon = ({ width, height, color, direction, style, ...rest }) => {
  const theme = useContext(ThemeContext);
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width={width} height={height} style={{ ...style, transform: arrowDirections[direction] }} {...rest}>
      <g fill="none" fillRule="evenodd">
        <path d="M-2-2h24v24H-2z" />
        <path
          d="M.736 0L0 .736 5.269 6 .005 11.264.741 12l5.628-5.632L6.721 6l-.352-.368z"
          fill={color ?? theme.colors.white}
        />
      </g>
    </svg>
  );
};

export const UpArrowIcon = (props) => <ArrowIcon {...props} direction={ARROW_DIRECTIONS.UP} />;

/** @component */
export const RightArrowIcon = (props) => <ArrowIcon {...props} direction={ARROW_DIRECTIONS.RIGHT} />;

export const DownArrowIcon = (props) => <ArrowIcon {...props} direction={ARROW_DIRECTIONS.DOWN} />;

export const LeftArrowIcon = (props) => <ArrowIcon {...props} direction={ARROW_DIRECTIONS.LEFT} />;

ArrowIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
  direction: PropTypes.oneOf(Object.values(ARROW_DIRECTIONS)),
  style: PropTypes.shape({}),
};

ArrowIcon.defaultProps = {
  width: '6.7px',
  height: '12px',
  color: undefined,
  direction: ARROW_DIRECTIONS.RIGHT,
  style: undefined,
};

export default ArrowIcon;
