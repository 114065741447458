import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { Flex } from 'rebass/styled-components';

import { HeartSolidFillIcon, HeartSolidIcon } from '@/components/Icon';
import { useLoginDialogContext } from '@/context/LoginRegisterDialog';
import useLikeEstate from '@/hooks/mutations/useMutationLikeEstate';
import useAuth from '@/hooks/useAuth';

const LikeButton = ({ id, isFavorite, queryKey }) => {
  const router = useRouter();
  const { authenticated } = useAuth();

  const { setShowLoginDialog, setUrl } = useLoginDialogContext();

  const handleLikeError = () => {
    setShowLoginDialog(true);
    if (router.asPath.includes('?')) {
      setUrl(`${router.asPath}&like=${id}`);
    } else {
      setUrl(`${router.asPath}?like=${id}`);
    }
  };

  const { like, dislike } = useLikeEstate(id, queryKey, handleLikeError);

  const handleClick = async () => {
    if (isFavorite) {
      await dislike();
    } else {
      await like();
    }
  };

  const setLike = useCallback(async () => {
    if (isFavorite) {
      await dislike();
    } else {
      await like();
    }
  }, [dislike, isFavorite, like]);

  useEffect(() => {
    if (authenticated && router.query.like === id) {
      setLike();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  return (
    <>
      <Flex
        as="button"
        type="button"
        sx={{
          all: 'unset',
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
        }}
        onClick={handleClick}
      >
        {isFavorite ? <HeartSolidFillIcon /> : <HeartSolidIcon />}
      </Flex>
    </>
  );
};

LikeButton.propTypes = {
  id: PropTypes.string.isRequired,
  isFavorite: PropTypes.bool.isRequired,
  queryKey: PropTypes.string.isRequired,
};

LikeButton.defaultProps = {};

export default LikeButton;
