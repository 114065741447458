import { Flex } from 'rebass/styled-components';
import styled from 'styled-components';
import { backgroundImage, border, layout, shadow } from 'styled-system';

const CardContainer = styled(Flex)`
  ${backgroundImage};
  ${border};
  ${layout};
  ${shadow};
  &:focus,
  &:active,
  &:visited,
  &:focus-visible {
    outline: none;
    -webkit-appearance: none;
  }
`;
CardContainer.defaultProps = {
  flexDirection: 'column',
  width: ['100%', '100%', '100%'],
  backgroundColor: 'white',
  borderRadius: '10px',
  boxShadow: 'default',
  py: ['4px', '12px', '14px'],
  px: ['15px', '30px', '30px'],
};

/** @component */
export default CardContainer;
