import { useFormikContext } from 'formik';
import useTranslation from 'next-translate/useTranslation';
import PropTypes from 'prop-types';
import * as React from 'react';

import FormButton from '@/components/Inputs/FormButton';
import { useQueryTotalEstate } from '@/hooks';

const SubmitButton = ({ isInvert, data = [] }) => {
  const { t } = useTranslation('content-page-immobiliensuche');
  const content = t('filter', {}, { returnObjects: true });
  const { values, setFieldValue } = useFormikContext();

  const getFirstCityFromList = React.useMemo(() => {
    return data ? data.map((list) => list.options).flat()?.[0]?.label : undefined;
  }, [data]);

  const currValue = React.useMemo(() => {
    const [address, lat, lon] = values?.address?.split?.('#');

    return {
      ...values,
      address,
      lat,
      lon,
    };
  }, [values]);

  React.useEffect(() => {
    if (getFirstCityFromList) {
      setFieldValue('address', getFirstCityFromList);
    }
  }, [getFirstCityFromList, setFieldValue]);

  const { data: summaryResult } = useQueryTotalEstate(values?.address || currValue?.address, currValue);

  return (
    <FormButton
      isInvert
      submit
      Icon=""
      wrapperProps={{ display: ['block', 'block', 'flex'], justifyContent: 'flex-end' }}
      text={`${summaryResult ?? 0} ${content.actionButton}`}
      width="100%"
      variant={isInvert ? 'secondary' : 'primary'}
      notFullWidth
    />
  );
};

SubmitButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  isInvert: PropTypes.bool.isRequired,
};

export default SubmitButton;
