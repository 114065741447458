import { useFormikContext } from 'formik';
import P from 'prop-types';
import { Box } from 'rebass/styled-components';

import Loader from '@/components/Loader';

import Button from '../Button/Button';
import { RightArrowIcon } from '../Icon/ArrowIconUiKit';

const FormButton = ({ isInvert, variant, text, Icon, notFullWidth, wrapperProps, ...rest }) => {
  const { isSubmitting } = useFormikContext();

  return (
    <Box flex="1" {...wrapperProps}>
      {!(variant === 'secondary' && isSubmitting) ? (
        <Button
          width={notFullWidth ? 'auto' : ['100%']}
          my={isInvert ? ['0', '0'] : ['4', '6']}
          variant={variant}
          submit
          Icon={Icon}
          iconColor="secondary"
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
          {...rest}
        >
          {text}
        </Button>
      ) : (
        <Button
          isDisabled={isSubmitting}
          width={notFullWidth ? 'auto' : ['100%']}
          my={isInvert ? ['0', '0'] : ['4', '6']}
          style={{ height: '42px' }}
          variant="secondary"
          submit
          {...rest}
        >
          <Box>
            <Loader isLoading />
          </Box>
        </Button>
      )}
    </Box>
  );
};

FormButton.propTypes = {
  text: P.string.isRequired,
  variant: P.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  Icon: P.bool,
  notFullWidth: P.bool,
  // eslint-disable-next-line react/forbid-prop-types
  wrapperProps: P.object,
  isInvert: P.bool.isRequired,
};

FormButton.defaultProps = {
  Icon: RightArrowIcon,
  notFullWidth: false,
  wrapperProps: undefined,
};

export default FormButton;
