import useTranslation from 'next-translate/useTranslation';
import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { toast } from '@/components/Toaster/Toaster';
import useAuth from '@/hooks/useAuth';
import { userApi } from '@/services';

const useLikeEstate = (id, queryKey, onError) => {
  const { t: h } = useTranslation('common-toast');
  const toaster = h('toast', {}, { returnObjects: true });
  const { initialized, authenticated } = useAuth();
  const queryClient = useQueryClient();

  const handleLoginState = useCallback(
    async (cb) => {
      if (initialized && !authenticated) {
        onError?.();
      } else {
        await cb();
      }
    },
    [authenticated, initialized, onError]
  );

  const commonQueryConfig = useCallback(
    (kind) => {
      return {
        onMutate: async () => {
          await queryClient.cancelQueries(queryKey);

          const previousEstates = queryClient.getQueryData(queryKey);

          if (queryKey === ['savedEstates', id]) {
            queryClient.setQueryData(queryKey, (old) => ({
              ...old,
              result: old.result.filter((item) => item.id !== id),
            }));
          } else if (queryKey?.includes('savedEstatesSingle')) {
            queryClient.setQueryData(queryKey, () => kind === 'like');
          } else {
            queryClient.setQueryData(queryKey, (old) => ({
              ...old,
              result: old.result.map((item) => ({
                ...item,
                isFavorite: item.id === id ? kind === 'like' : item.isFavorite,
              })),
            }));
          }
          return { previousEstates };
        },
        onError: (err, newTodo, context) => {
          queryClient.setQueryData(queryKey, context.previousEstates);
        },
        onSettled: async () => {
          // if (queryKey !== 'topEstates') await queryClient.invalidateQueries(queryKey);
          await queryClient.invalidateQueries(queryKey);
        },
        onSuccess: () => {
          if (kind === 'like') {
            toast({
              type: 'success',
              message: toaster.realEstate.like,
              dismissable: true,
              withIcon: true,
            });
          } else {
            toast({
              type: 'success',
              message: toaster.realEstate.dislike,
              dismissable: true,
              withIcon: true,
            });
          }
        },
      };
    },
    [toaster.realEstate.dislike, toaster.realEstate.like, id, queryClient, queryKey]
  );

  const { mutateAsync: like } = useMutation(async () => {
    await userApi.put(`/rating/expose/${id}`);
  }, commonQueryConfig('like'));

  const { mutateAsync: dislike } = useMutation(async () => {
    await userApi.delete(`/rating/expose/${id}`);
  }, commonQueryConfig('dislike'));

  return {
    like: async () => handleLoginState(like),
    dislike: async () => handleLoginState(dislike),
  };
};

export default useLikeEstate;
